<script>
    import { _ } from "svelte-i18n";
    import { getData, postData } from "../../utils/ApiUtils.svelte";
    import Loader from "../../components/Loader.svelte";
    import { onMount } from "svelte";
    import SvelteTable from "svelte-table";
    import { router } from "tinro";

    export let organizationId;

    let file;

    let sheets = [];
    let selectedSheet;
    let selectedExerciseType;

    let sheetData;

    let dataOut = [];

    let columns = [];

    let columnMapping = {
        login: null,
        firstName: null,
        lastName: null,
        defaultPassword: null,
        rosterName: null,
    };

    let state = "NEW";

    let ExcelToJSON;

    onMount(() => {
        ExcelToJSON = function () {
            this.parseExcel = function (file) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    var data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: "binary",
                    });

                    sheets = workbook.SheetNames;

                    sheetData = workbook;
                    state = "OK";

                    if (sheets?.length === 1) {
                        selectedSheet = sheets[0];
                    }

                    // workbook.SheetNames.forEach(function (sheetName) {
                    //     // Here is your object
                    //     var XL_row_object = XLSX.utils.sheet_to_row_object_array(
                    //         workbook.Sheets[sheetName],
                    //     );
                    //     var json_object = JSON.stringify(XL_row_object);
                    //     console.log(json_object);
                    // });
                };

                reader.onerror = function (ex) {
                    console.log(ex);
                    state = "ERROR";
                };

                reader.readAsBinaryString(file);
            };
        };
    });

    function load(evt) {
        state = "LOADING";
        console.log("load");
        var files = evt.target.files; // FileList object
        var xl2json = new ExcelToJSON();
        xl2json.parseExcel(files[0]);
    }

    const mappingDictionary = {
        login: ["login", "student email"],
        firstName: ["firstname", "first name"],
        lastName: ["lastname", "last name"],
        defaultPassword: ["defaultpassword", "default password"],
        rosterCode: ["rostercode", "roster name"],
    };

    const findColumnMatch = (columns, potentialMatches) => {
        return columns.find((column) =>
            potentialMatches.some((match) =>
                column.toLowerCase().includes(match),
            ),
        );
    };

    function parse() {
        dataOut = XLSX.utils.sheet_to_row_object_array(
            sheetData.Sheets[selectedSheet],
        );

        columns = Object.keys(dataOut[0]);

        columnMapping = {
            login: findColumnMatch(columns, mappingDictionary.login),
            firstName: findColumnMatch(columns, mappingDictionary.firstName),
            lastName: findColumnMatch(columns, mappingDictionary.lastName),
            defaultPassword: findColumnMatch(
                columns,
                mappingDictionary.defaultPassword,
            ),
            rosterCode: findColumnMatch(columns, mappingDictionary.rosterCode),
        };

        console.log(111, dataOut);
    }

    let okCount = 0;
    let errorCount = 0;

    async function runImport() {
        state = "IMPORT";
        okCount = 0;
        errorCount = 0;

        let parts = chunkify(dataOut, 100, false);

        for (let i = 0; i < parts.length; i++) {
            let e = parts[i];
            await createExercise(e);
        }

        state = "DONE";
    }

    function mapDataToPayload(data) {
        return {
            login: data[columnMapping.login],
            firstName: data[columnMapping.firstName],
            lastName: data[columnMapping.lastName],
            passwordPlain: data[columnMapping.defaultPassword],
            rosterCodeList: [data[columnMapping.rosterName]],
            primaryOrganizationId: organizationId,
        };
    }

    async function createExercise(data) {
        let payloadData = data.map((e) => mapDataToPayload(e));

        await postData(
            `api/school-admin/student-accounts/organization/${organizationId}/batch`,
            payloadData,
        )
            .then((data) => {
                okCount += data.length;
            })
            .catch((error) => {
                errorCount += data.length;
                console.log("error courses", error);
            });
    }

    function chunkify(a, n, balanced) {
        if (n < 2) return [a];

        var len = a.length,
            out = [],
            i = 0,
            size;

        if (len % n === 0) {
            size = Math.floor(len / n);
            while (i < len) {
                out.push(a.slice(i, (i += size)));
            }
        } else if (balanced) {
            while (i < len) {
                size = Math.ceil((len - i) / n--);
                out.push(a.slice(i, (i += size)));
            }
        } else {
            n--;
            size = Math.floor(len / n);
            if (len % size === 0) size--;
            while (i < size * n) {
                out.push(a.slice(i, (i += size)));
            }
            out.push(a.slice(size * n));
        }

        return out;
    }
</script>

<svelte:head>
    <script
        src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.8.0/jszip.js"
    ></script>
    <script
        src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.8.0/xlsx.js"
    ></script>
</svelte:head>

<main class="container-fluid px-4 s-container-fluid">
    <div class="s-content-spacing">
        <div class="s-content-header">
            <div class="s-content-header-title">
                {$_("ORGANIZATION_MANAGEMENT.STUDENTS_IN_ORG")} - {$_(
                    "IMPORT_STUDENT.IMPORT",
                )}
            </div>
        </div>
    </div>

    <div class="s-content-spacing">
        <div class="card shadow">
            <div class="card-body">
                <div>
                    <a
                        href="./assets/csv/SensayCreateAccountsSample.xlsx"
                        target="_blank"
                        >{$_("IMPORT_STUDENT.DOWNLOAD_SAMPLE")}</a
                    >
                </div>
                <div>
                    <label class="form-label" for="fileInput"
                        >{$_("IMPORT_STUDENT.UPLOAD_XLSX_OR_CSV_FILE")}</label
                    >
                    <input
                        class="form-control"
                        id="fileInput"
                        type="file"
                        bind:value={file}
                        on:change={load}
                    />
                </div>

                <div>
                    <label class="form-label" for="sheetSelect"
                        >{$_("IMPORT_STUDENT.SELECT_SHEET")}</label
                    >
                    <select
                        class="form-control"
                        id="sheetSelect"
                        bind:value={selectedSheet}
                        disabled={sheets?.length === 0}
                    >
                        {#each sheets as s, i}
                            <option value={s}>{s}</option>
                        {:else}
                            <option value="">{$_("NO_DATA")}</option>
                        {/each}
                    </select>
                </div>

                <div class="mt-2">
                    {#if organizationId && selectedSheet && dataOut}
                        <button
                            class="btn btn-primary s-button-primary"
                            on:click={parse}
                            >{$_("IMPORT_STUDENT.READ_SHEET")}</button
                        >
                    {/if}
                </div>

                <div>
                    {#if selectedSheet && dataOut}
                        <!-- {JSON.stringify(dataOut)} -->
                        <table class="table">
                            <thead>
                                <th
                                    >Student Email<br />
                                    <select bind:value={columnMapping.login}>
                                        {#each columns as c}
                                            <option value={c}>{c}</option>
                                        {/each}
                                    </select>
                                </th>
                                <th
                                    >First Name<br />
                                    <select
                                        bind:value={columnMapping.firstName}
                                    >
                                        {#each columns as c}
                                            <option value={c}>{c}</option>
                                        {/each}
                                    </select>
                                </th>
                                <th
                                    >Last Name<br />
                                    <select bind:value={columnMapping.lastName}>
                                        {#each columns as c}
                                            <option value={c}>{c}</option>
                                        {/each}
                                    </select></th
                                >
                                <th
                                    >Default Password<br /><select
                                        bind:value={columnMapping.defaultPassword}
                                    >
                                        {#each columns as c}
                                            <option value={c}>{c}</option>
                                        {/each}
                                    </select></th
                                >
                                <th
                                    >Roster Name<br /><select
                                        bind:value={columnMapping.rosterName}
                                    >
                                        <option value={undefined}>N/A</option>
                                        {#each columns as c}
                                            <option value={c}>{c}</option>
                                        {/each}
                                    </select></th
                                >
                            </thead>
                            <tbody>
                                {#each dataOut as r, i}
                                    <tr>
                                        <td>{r[columnMapping.login]}</td>
                                        <td>{r[columnMapping.firstName]}</td>
                                        <td>{r[columnMapping.lastName]}</td>
                                        <td
                                            ><pre>{r[
                                                    columnMapping
                                                        .defaultPassword
                                                ]}</pre></td
                                        >
                                        <td
                                            >{r[columnMapping.rosterName] ||
                                                ""}</td
                                        >
                                    </tr>
                                {/each}
                            </tbody>
                        </table>
                    {/if}
                </div>

                <div>
                    {#if state === "IMPORT" || state === "LOADING"}<Loader />
                    {:else if organizationId && selectedSheet && dataOut?.length > 0}
                        <button
                            class="btn btn-primary s-button-primary"
                            on:click={runImport}
                            >{$_("IMPORT_STUDENT.RUN_IMPORT")}</button
                        >
                    {:else}{$_("IMPORT_STUDENT.FILL_DATA")}
                    {/if}
                </div>

                <div>
                    {#if okCount || errorCount}
                        {$_("IMPORT_STUDENT.SUCCESS_COUNT")}
                        {okCount} <br />
                        {#if errorCount > 0}
                            {$_("IMPORT_STUDENT.ERROR_COUNT")}
                            {errorCount}<br />
                        {/if}
                        {$_("IMPORT_STUDENT.TOTAL_COUNT")}
                        {dataOut.length}<br />
                        {#if okCount === dataOut.length}{$_(
                                "IMPORT_STUDENT.SUCCESS",
                            )}{:else}{$_("IMPORT_STUDENT.ERROR_IMPORT")}
                        {/if}{/if}
                </div>
            </div>
        </div>
    </div>
</main>

<style>
    :global(.recordingRow) {
        cursor: pointer;
    }
    :global(.recordingRow:hover) {
        background-color: whitesmoke;
    }
</style>
