<script>
    import { _ } from "svelte-i18n";
    import { router } from "tinro";
    import { getData, postData } from "../../utils/ApiUtils.svelte";
    import Loader from "../../components/Loader.svelte";
    import { onMount } from "svelte";

    onMount(async () => {
        recalculate();
    });

    let numberOfStudents = 1;
    let numberOfMonths = 12;

    let currency = "NTD";

    let price = 100;
    let total = 0;

    function recalculate() {
        if (currency === "NTD") {
            if (numberOfStudents < 50) {
                if (numberOfMonths <= 3) {
                    price = 250;
                } else if (numberOfMonths <= 6) {
                    price = 200;
                } else if (numberOfMonths <= 12) {
                    price = 150;
                } else {
                    price = 120;
                }
            } else if (numberOfStudents <= 100) {
                if (numberOfMonths <= 3) {
                    price = 200;
                } else if (numberOfMonths <= 6) {
                    price = 180;
                } else if (numberOfMonths <= 12) {
                    price = 150;
                } else {
                    price = 120;
                }
            } else if (numberOfStudents <= 200) {
                if (numberOfMonths <= 3) {
                    price = 180;
                } else if (numberOfMonths <= 6) {
                    price = 170;
                } else if (numberOfMonths <= 12) {
                    price = 140;
                } else {
                    price = 110;
                }
            } else {
                if (numberOfMonths <= 3) {
                    price = 170;
                } else if (numberOfMonths <= 6) {
                    price = 160;
                } else if (numberOfMonths <= 12) {
                    price = 130;
                } else {
                    price = 100;
                }
            }
        } else {
            if (numberOfStudents < 50) {
                if (numberOfMonths <= 3) {
                    price = 6;
                } else if (numberOfMonths <= 6) {
                    price = 5;
                } else if (numberOfMonths <= 12) {
                    price = 4;
                } else {
                    price = 4;
                }
            } else if (numberOfStudents <= 100) {
                if (numberOfMonths <= 3) {
                    price = 6;
                } else if (numberOfMonths <= 6) {
                    price = 5;
                } else if (numberOfMonths <= 12) {
                    price = 4;
                } else {
                    price = 4;
                }
            } else if (numberOfStudents <= 200) {
                if (numberOfMonths <= 3) {
                    price = 5;
                } else if (numberOfMonths <= 6) {
                    price = 4;
                } else if (numberOfMonths <= 12) {
                    price = 4;
                } else {
                    price = 4;
                }
            } else {
                if (numberOfMonths <= 3) {
                    price = 5;
                } else if (numberOfMonths <= 6) {
                    price = 4;
                } else if (numberOfMonths <= 12) {
                    price = 4;
                } else {
                    price = 4;
                }
            }
        }

        total = (numberOfStudents * numberOfMonths * price).toFixed(0);
    }
</script>

<main class="container-fluid px-4 s-container-fluid">
    <div class="s-content-spacing">
        <div class="s-content-header">
            <div class="s-content-header-title">
                {$_("REQUEST_QUOTE")}
            </div>
        </div>
    </div>

    <div class="s-content-spacing">
        <div class="card shadow">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h1>{$_("REQUEST_QUOTE.PAST_QUOTES")}</h1>
                        <ul>
                            <li>ABC</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="s-content-spacing">
        <div class="card shadow">
            <div class="card-body">
                <div class="row">
                    <div class="col-2">
                        <label for="customRange3" class="form-label"
                            >{$_("REQUEST_QUOTE.SELECT_CURRENCY")}</label
                        >
                        <select
                            class="form-select"
                            aria-label="Default select example"
                            bind:value={currency}
                            on:change={recalculate}
                        >
                            <option value="NTD">NTD</option>
                            <option value="USD">USD</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <label for="customRange3" class="form-label"
                            >{$_("REQUEST_QUOTE.NUMBER_OF_STUDENTS")}: {numberOfStudents}</label
                        >
                        <input
                            type="range"
                            class="form-range"
                            min="1"
                            max="100"
                            step="1"
                            id="customRange3"
                            bind:value={numberOfStudents}
                            on:change={recalculate}
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label for="customRange3" class="form-label"
                            >{$_("REQUEST_QUOTE.NUMBER_OF_MONTHS")}: {numberOfMonths}</label
                        >
                        <input
                            type="range"
                            class="form-range"
                            min="1"
                            max="12"
                            step="1"
                            id="customRange3"
                            bind:value={numberOfMonths}
                            on:change={recalculate}
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        {$_("REQUEST_QUOTE.PRICE_PER_UNIT_PER_MONTH")}:
                    </div>
                    <div class="col-2">
                        {price}
                        {currency}
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        {$_("REQUEST_QUOTE.TOTAL")}:
                    </div>
                    <div class="col-2">
                        {total}
                        {currency}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button class="btn btn-primary s-button-primary"
                            >{$_("REQUEST_QUOTE.REQUEST_QUOTE")}</button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<style>
</style>
