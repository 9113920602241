<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Loader from "components/Loader.svelte";
  import Readability from "components/Readability.svelte";
  import { onMount } from "svelte";
  import {
    getData,
    postData,
    putData,
    deleteData,
    postFile,
  } from "utils/ApiUtils.svelte";
  import Breadcrumb from "components/Breadcrumb.svelte";
  import * as yup from "yup";
  import FilePond, { registerPlugin, supported } from "svelte-filepond";
  import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
  import FilePondPluginImagePreview from "filepond-plugin-image-preview";
  import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    userDetails,
    userConfig,
  } from "../../../components/DataStore";
  import { generateUUID } from "utils/FormatUtils.svelte";
  import TextAreaAutoresize from "components/TextAreaAutoresize.svelte";
  import QuestionScoringComponent from "./QuestionScoringComponent.svelte";
  import SSMLEditor from "./VisualComponentns/SSMLEditor.svelte";

  import { exerciseData } from "./ExerciseEdit/exerciseEditStore";

  let exerciseDetailStatus = "loading";
  let readabilityDisplayFlag = false;
  let validationError;

  let courseConfigData = {};
  let courseConfigStatus = "loading";

  let questionScoring = {
    minWords: "",
    minDurationSec: "",
    containWords: "",
    answer: "",
  };

  let ssmlFlag = false;
  let ssmlEditor;

  // Form Validation
  let errors = {};
  const schemaRead = yup.object().shape({
    title: yup.string().trim().required("EXERCISE_VALIDATION_ERROR_NO_NAME"),
    text: yup
      .string()
      .trim()
      .typeError("EXERCISE_VALIDATION_ERROR_READ_NO_TEXT")
      .required("EXERCISE_VALIDATION_ERROR_READ_NO_TEXT"),
  });
  const schemaVideo = yup.object().shape({
    title: yup.string().trim().required("EXERCISE_VALIDATION_ERROR_NO_NAME"),
    youtubeUrl: yup
      .string()
      .typeError("EXERCISE_VALIDATION_ERROR_VIDEO_URL_INVALID")
      .required("EXERCISE_VALIDATION_ERROR_VIDEO_URL_INVALID")
      .url("EXERCISE_VALIDATION_ERROR_VIDEO_URL_INVALID")
      .test(
        "valid YouTube",
        "EXERCISE_VALIDATION_ERROR_VIDEO_URL_INVALID",
        (value) =>
          value.match(
            /^.*(youtu.be\/|v\/|u\/\w\/|embed|shorts\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/,
          ),
      ),
    question: yup.string().nullable(),
  });
  const schemaQuestion = yup.object().shape({
    title: yup.string().trim().required("EXERCISE_VALIDATION_ERROR_NO_NAME"),
    question: yup
      .string()
      .trim()
      .required("EXERCISE_VALIDATION_ERROR_NO_QUESTION"),
  });
  const schemaReadingWithImage = yup.object().shape({
    title: yup.string().trim().required("EXERCISE_VALIDATION_ERROR_NO_NAME"),
    imageUrl: yup
      .string()
      .typeError("EXERCISE_VALIDATION_ERROR_IMAGE_NO_FILE")
      .url()
      .required("EXERCISE_VALIDATION_ERROR_IMAGE_NO_FILE"),
    answer: yup
      .string()
      .trim()
      .typeError("EXERCISE_VALIDATION_ERROR_READ_NO_TEXT")
      .required("EXERCISE_VALIDATION_ERROR_READ_NO_TEXT"),
  });
  const schemaQuestionFixAnswer = yup.object().shape({
    title: yup.string().trim().required("EXERCISE_VALIDATION_ERROR_NO_NAME"),
    question: yup
      .string()
      .trim()
      .required("EXERCISE_VALIDATION_ERROR_NO_QUESTION"),
    answer: yup.string().trim().required("EXERCISE_VALIDATION_ERROR_NO_ANSWER"),
  });

  const schemaSelfPractice = yup.object().shape({
    title: yup.string().trim().required("EXERCISE_VALIDATION_ERROR_NO_NAME"),
  });
  const schemaImage = yup.object().shape({
    title: yup.string().trim().required("EXERCISE_VALIDATION_ERROR_NO_NAME"),
    imageUrl: yup
      .string()
      .typeError("EXERCISE_VALIDATION_ERROR_IMAGE_NO_FILE")
      .url()
      .required("EXERCISE_VALIDATION_ERROR_IMAGE_NO_FILE"),
    question: yup.string().nullable(),
  });

  const schemaAdvancedScoring = yup
    .object()
    .shape({
      containWords: yup.string(),
      answer: yup.string(),
    })
    .test(
      "eitherOne",
      "ADVANCED_SCORING_NOT_ALLOWED_TO_BOTH_KEYWORDS_AND_ANSWER",
      (value) => {
        console.log("eitherOne");
        return (
          !value?.enabled ||
          (value.enabled === true && !(value.containWords && value.answer))
        );
      },
    );

  $: exerciseReadCharCount = $exerciseData?.text?.replace(
    /[^A-Z0-9]+/gi,
    "",
  ).length;

  $: exerciseAnswerCharCount = $exerciseData?.answer?.replace(
    /[^A-Z0-9]+/gi,
    "",
  ).length;

  exerciseData.subscribe((x) => {
    exerciseDetailStatus = "ok";
  });

  onMount(async () => {
    registerPlugin(
      FilePondPluginImageExifOrientation,
      FilePondPluginImagePreview,
      FilePondPluginFileValidateType,
    );
    if ($currentCourseId && $currentLessonId && $currentExerciseId) {
      getExercise($currentCourseId, $currentLessonId, $currentExerciseId);
    } else {
      $exerciseData = {
        type: "READ",
        title: "",
        text: "",
        youtubeUrl: "",
        question: "",
        imageUrl: "",
        data: "{}",
      };
      $exerciseData.key = generateToken();
      exerciseDetailStatus = "new";
    }
    getCourseConfig($currentCourseId);
    
  });

  // Register the plugins

  // a reference to the component, used to call FilePond methods
  let pond;

  // pond.getFiles() will return the active files

  // the name to use for the internal file input
  let name = "filepond";
  let isUploading = false;

  // handle filepond events
  function handleInit() {
    console.log("FilePond has initialised");
    if (pond){
      pond.removeFiles("*");
    }    
    //    pond.addFile('https://storage.googleapis.com/shuoshuo/exericse-images/01.jpg');
    if (
      ($exerciseData.type === "IMAGE" || $exerciseData.type === "READ_IMAGE") &&
      $exerciseData.imageUrl
    ) {
      console.log($exerciseData.imageUrl);
      pond.addFile(decodeURI($exerciseData.imageUrl));
    }
  }

  function handleAddFile(err, fileItem) {
    console.log("A file has been added", fileItem);
    isUploading = true;

    $exerciseData.imageName = fileItem.filename;

    if (err) {
      $exerciseData.imageName = undefined;
      $exerciseData.imageUrl = undefined;
    } else {
      let fileParams = {
        uploadType: "media",
        name: generateUUID() + fileItem.filename,
        key: "AIzaSyBgFT_wbOWOauPZpCWoBiRVGmgFdfHvr6o",
      };

      //Upload Recording
      postFile(
        "https://storage.googleapis.com/upload/storage/v1/b/sensayimage/o/",
        fileParams,
        fileItem.file,
      )
        .then((data) => {
          console.log("postFile", data);
          $exerciseData.imageUrl = data.mediaLink;
          isUploading = false;
        })
        .catch((error) => {
          console.log("error upload to storage", error);
        });
    }
  }

  function handleRemoveFile() {
    $exerciseData.imageName = undefined;
    $exerciseData.imageUrl = undefined;
    console.log("handleRemoveFile");
  }

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    currentPage.update((x) => e);
    router.goto("/" + e);
  }

  function getExercise(courseId, lessonId, exerciseId) {
    getData(
      "api/courses/" +
        courseId +
        "/lessons/" +
        lessonId +
        "/exercises/" +
        exerciseId,
    )
      .then((data) => {
        console.log("exercises successful", data);
        exerciseData.update((x) => data);
        currentExerciseName.update((x) => $exerciseData?.title);
        currentCourseName.update((x) => $exerciseData?.courseName);
        currentLessonName.update((x) => $exerciseData?.lessonName);
        exerciseDetailStatus = "loaded";

        let x = JSON.parse($exerciseData?.data);
        questionScoring = {
          minWords: x?.qs?.minWords,
          minDurationSec: x?.qs?.minDurationSec,
          containWords: x?.qs?.containWords?.join(","),
          answer: $exerciseData.answer,
        };

        if (
          x?.qs?.minWords ||
          x?.qs?.minDurationSec ||
          x?.qs?.containWords.length > 0 ||
          $exerciseData.answer
        ) {
          questionScoring["enabled"] = true;
        }

        handleInit()

        //pond.addFile(data.imageUrl);
      })
      .catch((error) => {
        console.log("error exercises", error);
        exerciseDetailStatus = "error";
      });
  }

  function getCourseConfig(courseId) {
    getData(`api/courses/${courseId}/config`)
      .then((data) => {
        console.log("course config successful", data);
        courseConfigData = data;
        courseConfigStatus = "loaded";
      })
      .catch((error) => {
        console.log("error exercises", error);
        courseConfigStatus = "error";
      });
  }

  async function handleSubmit(moveOut, createNew) {
    if (!$exerciseData.type) {
      validationError = "EXERCISE_VALIDATION_ERROR_NO_TYPE";
      exerciseDetailStatus = "validationError";
      return;
    }

    if (isUploading) {
      return;
    }

    try {
      switch ($exerciseData.type) {
        case "READ":
          await schemaRead.validate($exerciseData, { abortEarly: false });
          break;
        case "VIDEO":
          await schemaAdvancedScoring.validate(questionScoring, {
            abortEarly: false,
          });
          await schemaVideo.validate($exerciseData, { abortEarly: false });
          break;
        case "QUESTION":
          await schemaAdvancedScoring.validate(questionScoring, {
            abortEarly: false,
          });
          await schemaQuestion.validate($exerciseData, { abortEarly: false });

          break;
        case "READ_IMAGE":
          await schemaReadingWithImage.validate($exerciseData, {
            abortEarly: false,
          });
          break;
        case "QUESTION_FIX_ANSWER":
          await schemaQuestionFixAnswer.validate($exerciseData, {
            abortEarly: false,
          });
          break;
        case "SELF_PRACTICE":
          await schemaSelfPractice.validate($exerciseData, {
            abortEarly: false,
          });
          break;
        case "IMAGE":
          await schemaAdvancedScoring.validate(questionScoring, {
            abortEarly: false,
          });
          await schemaImage.validate($exerciseData, { abortEarly: false });
          break;
        default:
          break;
      }

      // for debugging validation result
      // alert(JSON.stringify($exerciseData, null, 2))
      errors = {};
      saveExercise(moveOut, createNew);
    } catch (err) {
      errors = err.inner.reduce((acc, err) => {
        if (!err.path) {
          return { ...acc, [err.type]: err.message };
        }
        return { ...acc, [err.path]: err.message };
      }, {});
    }

    console.log("errors", errors);
  }

  function replaceCharactersWithTTSProblems(text) {
    text = text.replaceAll(/\u2019/g, "'");
    text = text.replaceAll(/\u2026/g, ".");
    text = text.replaceAll(/\u3002/g, ".");
    text = text.replaceAll(/\u3001/g, ",");
    text = text.replaceAll(/\u201C/g, '"');
    text = text.replaceAll(/\u201D/g, '"');
    text = text.replaceAll(/\uFF1F/g, '?');
    text = text.replaceAll(/\uFF0E/g, '.');
    text = text.replaceAll(/\uFF01/g, '!');
    text = text.replaceAll(/\uFF02/g, '"');
    text = text.replaceAll(/\u3003/g, '"');

    return text;
  }

  async function saveExercise(moveOut = false, createNew = false) {
    exerciseDetailStatus = "saving";

    if (ssmlFlag && ssmlEditor) {
      await ssmlEditor.upload();
    }

    if ($exerciseData?.text) {
      $exerciseData.text = replaceCharactersWithTTSProblems(
        $exerciseData?.text,
      );
    }
    if ($exerciseData?.question) {
      $exerciseData.question = replaceCharactersWithTTSProblems(
        $exerciseData?.question,
      );
    }
    if ($exerciseData?.answer) {
      $exerciseData.answer = replaceCharactersWithTTSProblems(
        $exerciseData?.answer,
      );
    }

    let payloadData = {};
    let exerciseDataPayload = {};

    if (questionScoring.enabled) {
      exerciseDataPayload = {
        qs: {
          minWords: questionScoring?.minWords,
          minDurationSec: questionScoring?.minDurationSec,
          containWords: questionScoring?.containWords
            ?.trim()
            ?.split(/[ ,]+/)
            .map((w) => w.trim())
            .filter((w) => w.length > 0),
        },
      };
    } else {
      questionScoring["answer"] = "";
    }

    if ($exerciseData.type === "READ") {
      payloadData = {
        key: $exerciseData?.key,
        language: "",
        data: "{}",
        text: $exerciseData?.text === null ? "" : $exerciseData?.text,
        title: $exerciseData?.title,
        type: $exerciseData.type,
        multiChoiceAnswers: [null, null, null, null],
        ssml: $exerciseData.ssml,
        ttsUrl: $exerciseData.ttsUrl,
        ttsWords: $exerciseData.ttsWords,
      };
    } else if ($exerciseData.type === "VIDEO") {
      let youtubeUrlEmbed = extractYoutube($exerciseData?.youtubeUrl);
      payloadData = {
        key: $exerciseData?.key,
        language: "",
        data: JSON.stringify(exerciseDataPayload),
        title: $exerciseData?.title,
        type: $exerciseData.type,
        question:
          $exerciseData?.question === null ? "" : $exerciseData?.question,
        youtubeUrl: youtubeUrlEmbed,
        answer: questionScoring?.answer,
      };
    } else if ($exerciseData.type === "QUESTION") {
      payloadData = {
        key: $exerciseData?.key,
        language: "",
        data: JSON.stringify(exerciseDataPayload),
        text: $exerciseData?.text,
        title: $exerciseData?.title,
        type: $exerciseData.type,
        question: $exerciseData?.question,
        answer: questionScoring?.answer,
      };
    } else if ($exerciseData.type === "SELF_PRACTICE") {
      payloadData = {
        key: $exerciseData?.key,
        language: "",
        data: "{}",
        text: $exerciseData?.text,
        title: $exerciseData?.title,
        type: $exerciseData.type,
      };
    } else if ($exerciseData.type === "IMAGE") {
      payloadData = {
        key: $exerciseData?.key,
        language: "",
        data: JSON.stringify(exerciseDataPayload),
        text: $exerciseData?.text,
        title: $exerciseData?.title,
        type: $exerciseData.type,
        question:
          $exerciseData?.question === null ? "" : $exerciseData?.question,
        imageUrl: $exerciseData.imageUrl,
        imageName: $exerciseData.imageName,
        answer: questionScoring?.answer,
      };
    } else if ($exerciseData.type === "READ_IMAGE") {
      payloadData = {
        key: $exerciseData?.key,
        language: "",
        data: JSON.stringify(exerciseDataPayload),
        text: $exerciseData?.text,
        title: $exerciseData?.title,
        type: $exerciseData.type,
        question:
          $exerciseData?.question === null ? "" : $exerciseData?.question,
        imageUrl: $exerciseData.imageUrl,
        imageName: $exerciseData.imageName,
        answer: $exerciseData.answer,
      };
    } else if ($exerciseData.type === "QUESTION_FIX_ANSWER") {
      payloadData = {
        key: $exerciseData?.key,
        language: "",
        data: JSON.stringify(exerciseDataPayload),
        text: $exerciseData?.text,
        title: $exerciseData?.title,
        type: $exerciseData.type,
        question: $exerciseData?.question,
        answer: $exerciseData?.answer,
      };
    }

    console.log("save exercise", payloadData);

    if (!$exerciseData?.id) {
      postData(
        "api/courses/" +
          $currentCourseId +
          "/lessons/" +
          $currentLessonId +
          "/exercises",
        payloadData,
      )
        .then((data) => {
          console.log("courses successful", data);

          exerciseData.update((x) => data);
          currentExerciseId.update((x) => data?.id);
          currentCourseName.update((x) => $exerciseData?.courseName);
          currentLessonName.update((x) => $exerciseData?.lessonName);
          currentExerciseName.update((x) => $exerciseData?.title);
          exerciseDetailStatus = "saved";

          mixpanel.track("Teacher Save Exercise Button Clicked", {
            New: true,
            "Exercise ID": $currentExerciseId,
            "Exercise Type": $exerciseData?.type,
          });

          if (createNew) {
            router.subscribe((_) => window.scrollTo(0, 0));
            currentExerciseId.update((x) => undefined);
            exerciseDetailStatus = "ok";
            $exerciseData = {
              type: $exerciseData.type,
              title: "",
              text: "",
              youtubeUrl: "",
              question: "",
              imageUrl: "",
              data: "{}",
            };
            $exerciseData.key = generateToken();
            questionScoring = {
              minWords: "",
              minDurationSec: "",
              containWords: "",
            };
            if (pond) {
              pond?.removeFiles();
            }
          }

          if (moveOut) {
            clickChangePage(
              "courses/" +
                $currentCourseId +
                "/lesson-detail/" +
                $currentLessonId,
            );
          }
        })
        .catch((error) => {
          console.log("error courses", error);
          exerciseDetailStatus = "error";
        });
    } else {
      mixpanel.track("Teacher Save Exercise Button Clicked", {
        New: false,
        "Exercise ID": $exerciseData?.id,
        "Exercise Type": $exerciseData?.type,
      });
      putData("api/exercises/" + $exerciseData?.id, payloadData)
        .then((data) => {
          console.log("courses successful", data);

          exerciseData.update((x) => data);
          exerciseDetailStatus = "saved";

          if (moveOut) {
            //clickChangePage("lesson-detail");
            clickChangePage(
              "courses/" +
                $currentCourseId +
                "/lesson-detail/" +
                $currentLessonId,
            );
          }

          if (createNew) {
            router.subscribe((_) => window.scrollTo(0, 0));
            currentExerciseId.update((x) => undefined);
            exerciseDetailStatus = "ok";
            $exerciseData = {};
            $exerciseData.key = generateToken();
          }
        })
        .catch((error) => {
          console.log("error exercise", error);
          exerciseDetailStatus = "error";
        });
    }
  }

  function cancelExerciseEdit() {
    if (exerciseDetailStatus === "saved" || exerciseDetailStatus === "loaded") {
      clickChangePage(
        "courses/" +
          $currentCourseId +
          "/lessons/" +
          $currentLessonId +
          "/exercise-detail/" +
          $currentExerciseId,
      );
    } else {
      if (confirm($_("EDIT_CANCEL_DIALOG"))) {
        if ($exerciseData?.id) {
          //clickChangePage("exercise-detail");
          clickChangePage(
            "courses/" +
              $currentCourseId +
              "/lessons/" +
              $currentLessonId +
              "/exercise-detail/" +
              $currentExerciseId,
          );
        } else {
          clickChangePage(
            "courses/" +
              $currentCourseId +
              "/lesson-detail/" +
              $currentLessonId,
          );
        }
      } else {
        // Do nothing!
        // console.log('Cancel')
      }
    }
  }

  function deleteExercise() {
    mixpanel.track("Teacher Delete Button Clicked", {
      "Button Location": "Exercise Edit Page",
    });
    // console.log('delete exercise')
    if (
      confirm($_("EXERCISE_DELETE_CONFIRMATION_DIALOG") + $exerciseData?.title)
    ) {
      // console.log('Thing was saved to the database.')
      deleteData("api/exercises/" + $currentExerciseId)
        .then((data) => {
          // console.log('Exericse successful', data)

          currentExerciseId.update((x) => undefined);
          currentExerciseName.update((x) => undefined);

          clickChangePage(
            "courses/" +
              $currentCourseId +
              "/lesson-detail/" +
              $currentLessonId,
          );
        })
        .catch((error) => {
          // console.log('error Exericse', error)
        });
    } else {
      console.log("Thing was not saved to the database.");
    }
  }

  function selectExerciseType(type) {
    // console.log('selectExerciseType', type)
    $exerciseData.type = type;
  }

  function generateToken() {
    let tokenLength = 6;
    let result = "";
    let characters = "abcdefghijkmnopqrstuvwxyz023456789";
    let charactersLength = characters.length;

    for (var i = 0; i < tokenLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function extractYoutube(url) {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|[embed|shorts]\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      // Do anything for being valid
      // if need to change the url to embed url then use below line
      return "https://www.youtube.com/embed/" + match[2] + "?autoplay=0";
    } else {
      // Do anything for not being valid
      console.log("INVALID YOUTUBE LINK");
    }
  }

  function handleCtrlEnter(event) {
    // console.log('Save')
    handleSubmit(true);
  }

  function skipEnterOnInput(event) {
    console.log(event);
    if (event.key === "Enter" && !event.ctrlKey) {
      event.preventDefault();
    } else if (event.key === "Enter" && event.ctrlKey) {
      // console.log('Save')
      handleSubmit(true);
    }
  }

  function containsNonEnglish(text) {
    if (!text) {
      return false;
    }
    // Regular expression pattern for common and extended Han characters
    const containsNonEnglish = /^[a-zA-Z0-9\s.,:;?!$&%@<>{}=#()+"'-]*$/;

    // If any Chinese character is found, return true
    return !containsNonEnglish.test(text);
  }

  function containsLanguage(text, lang) {
    let pattern;

    switch (lang) {
      case "Arabic":
        pattern = /[\u0600-\u06FF]/;
        break;
      case "Chinese":
        pattern = /[\u4E00-\u9FFF\u3400-\u4DBF]/;
        break;
      case "Cyrillic":
        pattern = /[\u0400-\u04FF]/;
        break;
      case "Devanagari":
        pattern = /[\u0900-\u097F]/;
        break;
      case "Greek":
        pattern = /[\u0370-\u03FF]/;
        break;
      case "Hebrew":
        pattern = /[\u0590-\u05FF]/;
        break;
      case "Japanese":
        pattern = /[\u4E00-\u9FFF\u3400-\u4DBF\u30A0-\u30FF\u3040-\u309F]/;
        break;
      case "Korean":
        pattern = /[\uAC00-\uD7AF]/;
        break;
      default:
        return false;
    }

    return pattern.test(text);
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  {#if exerciseDetailStatus === "loading"}
    <Loader />
  {:else}
    <div class="s-content-spacing">
      <div class="s-content-header">
        <Breadcrumb />
      </div>
    </div>

    <div class="s-content-spacing">
      <form>
        <div class="card shadow">
          <div class="card-body">
            <div>
              <div>
                <div><h2>{$_("EXERCISE_TYPE_PROMPT")}</h2></div>
              </div>
              <div style="overflow-x: auto;">
                <div
                  class="btn-group"
                  role="group"
                  style="margin: 15px 0 20px 0"
                >
                  <button
                    type="button"
                    class="btn btn-secondary s-button-secondary s-mr-10px"
                    class:s-button-success={$exerciseData.type === "READ"}
                    on:click={() => selectExerciseType("READ")}
                    >{$_("READING")}</button
                  >
                    <button
                      type="button"
                      class="btn btn-secondary s-button-secondary s-mr-10px"
                      class:s-button-success={$exerciseData.type ===
                        "READ_IMAGE"}
                      on:click={() => selectExerciseType("READ_IMAGE")}
                      >{$_("EXERCISE_.READ_IMAGE")}</button
                    >
                    <button
                      type="button"
                      class="btn btn-secondary s-button-secondary s-mr-10px"
                      class:s-button-success={$exerciseData.type ===
                        "QUESTION_FIX_ANSWER"}
                      on:click={() => selectExerciseType("QUESTION_FIX_ANSWER")}
                      >{$_("EXERCISE_.QUESTION_FIX_ANSWER")}</button
                    >
                  <button
                    type="button"
                    class="btn btn-secondary s-button-secondary s-mr-10px"
                    class:s-button-success={$exerciseData.type === "QUESTION"}
                    on:click={() => selectExerciseType("QUESTION")}
                    >{$_("QUESTION")}</button
                  >
                  <button
                    type="button"
                    class="btn btn-secondary s-button-secondary s-mr-10px"
                    class:s-button-success={$exerciseData.type === "IMAGE"}
                    on:click={() => selectExerciseType("IMAGE")}
                    >{$_("IMAGE")}</button
                  >
                  
                  <button
                    type="button"
                    class="btn btn-secondary s-button-secondary s-mr-10px"
                    class:s-button-success={$exerciseData.type === "VIDEO"}
                    on:click={() => selectExerciseType("VIDEO")}
                    >{$_("VIDEO")}</button
                  >
                  <button
                    type="button"
                    class="btn btn-secondary s-button-secondary s-mr-10px"
                    class:s-button-success={$exerciseData.type ===
                      "SELF_PRACTICE"}
                    on:click={() => selectExerciseType("SELF_PRACTICE")}
                    >{$_("SELF_PRACTICE")}</button
                  >
                </div>
              </div>

              {#if $exerciseData.type === "READ"}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseDescription"
                      >{$_("EXERCISE_NAME")}</label
                    >
                    <div>
                      <small>{$_("EXERCISE_EDIT_PROMPT")}</small>
                    </div>
                    <input
                      id="exerciseDescription"
                      class="form-control"
                      class:validation-input-box-error={errors.title}
                      style="margin-bottom: 0;"
                      type="text"
                      placeholder={$_("EXERCISE_NAME")}
                      bind:value={$exerciseData.title}
                      on:keydown={skipEnterOnInput}
                    />
                    <div class="validation-text-error">
                      {#if errors.title}
                        {$_(errors.title)}
                      {/if}
                    </div>
                    <label for="exerciseTextToRead">{$_("TEXT_TO_READ")}</label>
                    <div>
                      <small>{$_("READING_INFO")}</small>
                    </div>

                    {#if $userConfig?.serverFlags?.ssmlEditor === true}
                      <input type="checkbox" bind:checked={ssmlFlag} />
                      <span>SSML</span>
                      {#if ssmlFlag}
                        <SSMLEditor
                          bind:this={ssmlEditor}
                          exerciseData={$exerciseData}
                        />{/if}
                    {/if}
                    <TextAreaAutoresize
                      id="exerciseTextToRead"
                      class={errors.text ? "validation-input-box-error" : ""}
                      style="margin-bottom: 0;"
                      bind:value={$exerciseData.text}
                      on:ctrlEnter={handleCtrlEnter}
                      placeholder={$_("EXERCISE_.TEXT_TO_READ_PLACEHOLDER")}
                    />
                    <div
                      class:exercise-char-length-error={exerciseReadCharCount >
                        300}
                    >
                      {$_("EXERCISE_.CHAR_COUNTER")}
                      {exerciseReadCharCount}
                      {#if exerciseReadCharCount > 300}
                        <span>
                          <i class="fas fa-exclamation-circle me-1" />{$_(
                            "EXERCISE_READ_RECORDING_LIMIT",
                          )}
                        </span>
                      {/if}
                      {#if containsNonEnglish($exerciseData.text)}
                        <span class="validation-text-error">
                          <i class="fas fa-exclamation-circle me-1" />{$_(
                            "EXERCISE_READ_USE_ONLY_ENGLISH",
                          )}
                        </span>
                      {/if}
                    </div>
                    <div class="validation-text-error">
                      {#if errors.text}
                        {$_(errors.text)}
                      {/if}
                    </div>
                  </div>
                </div>
              {:else if $exerciseData.type === "VIDEO"}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseDescription"
                      >{$_("EXERCISE_NAME")}</label
                    >
                    <div>
                      <small>{$_("EXERCISE_EDIT_PROMPT")}</small>
                    </div>
                    <input
                      id="exerciseDescription"
                      class="form-control"
                      class:validation-input-box-error={errors.title}
                      style="margin-bottom: 0;"
                      type="text"
                      placeholder={$_("EXERCISE_NAME")}
                      bind:value={$exerciseData.title}
                      on:keydown={skipEnterOnInput}
                    />
                    <div class="validation-text-error">
                      {#if errors.title}
                        {$_(errors.title)}
                      {/if}
                    </div>

                    <label for="youtubeLink">{$_("VIDEO_LINK")}</label>
                    <div>
                      <small>{$_("VIDEO_INPUT_PROMPT")}</small>
                    </div>
                    <input
                      id="youtubeLink"
                      class="form-control"
                      class:validation-input-box-error={errors.youtubeUrl}
                      style="margin-bottom: 0;"
                      type="text"
                      placeholder={$_("EXERCISE_.VIDEO_LINK_PLACEHOLDER")}
                      bind:value={$exerciseData.youtubeUrl}
                      on:keydown={skipEnterOnInput}
                    />
                    <div class="validation-text-error">
                      {#if errors.youtubeUrl}
                        {$_(errors.youtubeUrl)}
                      {/if}
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseQuestion"
                      >{$_("QUESTION_OPTIONAL")}</label
                    >
                    <div>
                      <small>{$_("QUESTION_INPUT_PROMPT")}</small>
                    </div>
                    <TextAreaAutoresize
                      id="exerciseQuestion"
                      bind:value={$exerciseData.question}
                      on:ctrlEnter={handleCtrlEnter}
                      placeholder={$_("EXERCISE_.QUESTION_PLACEHOLDER")}
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="col offset-lg-0">
                    {#if $userDetails?.userRole === "ADMIN" || $userConfig?.serverFlags?.questionScoring === true}
                      <QuestionScoringComponent
                        {errors}
                        bind:questionScoring
                        on:skipEnterOnInput={skipEnterOnInput}
                      />
                    {/if}
                  </div>
                </div>
              {:else if $exerciseData.type === "QUESTION"}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseDescription"
                      >{$_("EXERCISE_NAME")}</label
                    >
                    <div>
                      <small>{$_("EXERCISE_EDIT_PROMPT")}</small>
                    </div>
                    <input
                      id="exerciseDescription"
                      class="form-control"
                      style="margin-bottom: 0;"
                      class:validation-input-box-error={errors.title}
                      type="text"
                      placeholder={$_("EXERCISE_NAME")}
                      bind:value={$exerciseData.title}
                      on:keydown={skipEnterOnInput}
                    />
                    <div class="validation-text-error">
                      {#if errors.title}
                        {$_(errors.title)}
                      {/if}
                    </div>

                    <label for="exerciseQuestion">{$_("QUESTION")}</label>
                    <div>
                      <small>{$_("QUESTION_INPUT_PROMPT")}</small>
                    </div>
                    <TextAreaAutoresize
                      id="exerciseQuestion"
                      error={errors.question}
                      bind:value={$exerciseData.question}
                      on:ctrlEnter={handleCtrlEnter}
                      placeholder={$_("EXERCISE_.QUESTION_PLACEHOLDER")}
                    />
                    <div class="validation-text-error">
                      {#if errors.question}
                        {$_(errors.question)}
                      {/if}
                    </div>

                    {#if $userDetails?.userRole === "ADMIN" || $userConfig?.serverFlags?.questionScoring === true}
                      <QuestionScoringComponent
                        {errors}
                        bind:questionScoring
                        on:skipEnterOnInput={skipEnterOnInput}
                      />
                    {/if}
                  </div>
                </div>
              {:else if $exerciseData.type === "SELF_PRACTICE"}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseDescription"
                      >{$_("EXERCISE_NAME")}</label
                    >
                    <div>
                      <small>{$_("EXERCISE_EDIT_PROMPT")}</small>
                    </div>
                    <input
                      id="exerciseDescription"
                      class="form-control"
                      type="text"
                      style="margin-bottom: 0;"
                      class:validation-input-box-error={errors.title}
                      placeholder={$_("EXERCISE_NAME")}
                      bind:value={$exerciseData.title}
                      on:keydown={skipEnterOnInput}
                    />
                    <div class="validation-text-error">
                      {#if errors.title}
                        {$_(errors.title)}
                      {/if}
                    </div>
                    <i class="fas fa-info-circle me-1" />{$_(
                      "STUDENT_PROVIDE_OWN_TEXT",
                    )}
                  </div>
                </div>
              {:else if $exerciseData.type === "IMAGE"}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseDescription"
                      >{$_("EXERCISE_NAME")}</label
                    >
                    <div>
                      <small>{$_("EXERCISE_EDIT_PROMPT")}</small>
                    </div>
                    <input
                      id="exerciseDescription"
                      class="form-control"
                      style="margin-bottom: 0;"
                      class:validation-input-box-error={errors.title}
                      type="text"
                      placeholder={$_("EXERCISE_NAME")}
                      bind:value={$exerciseData.title}
                      on:keydown={skipEnterOnInput}
                    />
                    <div class="validation-text-error">
                      {#if errors.title}
                        {$_(errors.title)}
                      {/if}
                    </div>

                    <label for="imageUpload">{$_("IMAGE_UPLOAD")}</label>
                    <div>
                      <small
                        >{$_("IMAGE_UPLOAD_PROMPT")} JPG, PNG, GIF</small
                      >
                    </div>
                    <FilePond
                      bind:this={pond}
                      id="imageUpload"
                      {name}
                      acceptedFileTypes={"image/jpeg, image/png, image/gif, image/jpg"}
                      fileValidateTypeLabelExpectedTypes={$_(
                        "IMAGE_UPLOAD_TYPE_NOT_SUPPORTED",
                      ) + "JPG, PNG, GIF"}
                      allowMultiple={false}
                      oninit={handleInit}
                      onaddfile={handleAddFile}
                      onremovefile={handleRemoveFile}
                      labelIdle={$_("IMAGE_UPLOAD_LABEL")}
                    />
                    <div class="validation-text-error">
                      {#if errors.imageUrl}
                        {$_(errors.imageUrl)}
                      {/if}
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseQuestion"
                      >{$_("QUESTION_OPTIONAL")}</label
                    >
                    <div>
                      <small>{$_("QUESTION_INPUT_PROMPT")}</small>
                    </div>
                    <TextAreaAutoresize
                      id="exerciseQuestion"
                      bind:value={$exerciseData.question}
                      on:ctrlEnter={handleCtrlEnter}
                      placeholder={$_("EXERCISE_.QUESTION_PLACEHOLDER")}
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="col offset-lg-0">
                    {#if $userDetails?.userRole === "ADMIN" || $userConfig?.serverFlags?.questionScoring === true}
                      <QuestionScoringComponent
                        {errors}
                        bind:questionScoring
                        on:skipEnterOnInput={skipEnterOnInput}
                      />
                    {/if}
                  </div>
                </div>
              {:else if $exerciseData.type === "READ_IMAGE"}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseDescription"
                      >{$_("EXERCISE_NAME")}</label
                    >
                    <div>
                      <small>{$_("EXERCISE_EDIT_PROMPT")}</small>
                    </div>
                    <input
                      id="exerciseDescription"
                      class="form-control"
                      style="margin-bottom: 0;"
                      class:validation-input-box-error={errors.title}
                      type="text"
                      placeholder={$_("EXERCISE_NAME")}
                      bind:value={$exerciseData.title}
                      on:keydown={skipEnterOnInput}
                    />
                    <div class="validation-text-error">
                      {#if errors.title}
                        {$_(errors.title)}
                      {/if}
                    </div>

                    <label for="imageUpload">{$_("IMAGE_UPLOAD")}</label>
                    <div>
                      <small
                        >{$_("IMAGE_UPLOAD_PROMPT")} JPG, PNG, BMP, GIF, SVG</small
                      >
                    </div>
                    <FilePond
                      bind:this={pond}
                      id="imageUpload"
                      {name}
                      acceptedFileTypes={"image/jpeg, image/png, image/svg, image/gif, image/jpg, image/bmp"}
                      fileValidateTypeLabelExpectedTypes={$_(
                        "IMAGE_UPLOAD_TYPE_NOT_SUPPORTED",
                      ) + "JPG, PNG, BMP, GIF, SVG"}
                      allowMultiple={false}
                      oninit={handleInit}
                      onaddfile={handleAddFile}
                      onremovefile={handleRemoveFile}
                      labelIdle={$_("IMAGE_UPLOAD_LABEL")}
                    />
                    <div class="validation-text-error">
                      {#if errors.imageUrl}
                        {$_(errors.imageUrl)}
                      {/if}
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseQuestion"
                      >{$_("QUESTION_OPTIONAL_READ_IMAGE")}</label
                    >
                    <TextAreaAutoresize
                      id="exerciseQuestion"
                      bind:value={$exerciseData.question}
                      on:ctrlEnter={handleCtrlEnter}
                      placeholder={$_("EXERCISE_.QUESTION_PLACEHOLDER")}
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseTextToRead"
                      >{$_("EXERCISE_.EXPECTED_ANSWER")}</label
                    >
                    <div>
                      <small
                        >{$_("EXERCISE_.EXPECTED_ANSWER_DESCRIPTION")}</small
                      >
                    </div>
                    <TextAreaAutoresize
                      id="exerciseTextToRead"
                      class={errors.answer ? "validation-input-box-error" : ""}
                      style="margin-bottom: 0;"
                      bind:value={$exerciseData.answer}
                      on:ctrlEnter={handleCtrlEnter}
                      placeholder={$_("EXERCISE_.EXPECTED_ANSWER_PLACEHOLDER")}
                    />
                    <div
                      class:exercise-char-length-error={exerciseAnswerCharCount >
                        300}
                    >
                      {exerciseAnswerCharCount}
                      {#if exerciseAnswerCharCount > 300}
                        <span>
                          <i class="fas fa-exclamation-circle" />{$_(
                            "EXERCISE_.ANSWER_RECORDING_LIMIT",
                          )}
                        </span>
                      {/if}
                    </div>
                    <div class="validation-text-error">
                      {#if errors.answer}
                        {$_(errors.answer)}
                      {/if}
                    </div>
                  </div>
                </div>
              {:else if $exerciseData.type === "QUESTION_FIX_ANSWER"}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="exerciseDescription"
                      >{$_("EXERCISE_NAME")}</label
                    >
                    <div>
                      <small>{$_("EXERCISE_EDIT_PROMPT")}</small>
                    </div>
                    <input
                      id="exerciseDescription"
                      class="form-control"
                      style="margin-bottom: 0;"
                      class:validation-input-box-error={errors.title}
                      type="text"
                      placeholder={$_("EXERCISE_NAME")}
                      bind:value={$exerciseData.title}
                      on:keydown={skipEnterOnInput}
                    />
                    <div class="validation-text-error">
                      {#if errors.title}
                        {$_(errors.title)}
                      {/if}
                    </div>

                    <label for="exerciseQuestion">{$_("QUESTION")}</label>
                    <div>
                      <small>{$_("QUESTION_INPUT_PROMPT")}</small>
                    </div>
                    <TextAreaAutoresize
                      id="exerciseQuestion"
                      class={errors.question
                        ? "validation-input-box-error"
                        : ""}
                      bind:value={$exerciseData.question}
                      on:ctrlEnter={handleCtrlEnter}
                      placeholder="Question"
                    />
                    <div class="validation-text-error">
                      {#if errors.question}
                        {$_(errors.question)}
                      {/if}
                    </div>

                    <div class="form-row">
                      <div class="col offset-lg-0">
                        <label for="exerciseTextToRead"
                          >{$_("EXERCISE_.EXPECTED_ANSWER")}</label
                        >
                        <div>
                          <small
                            >{$_(
                              "EXERCISE_.EXPECTED_ANSWER_DESCRIPTION",
                            )}</small
                          >
                        </div>
                        <TextAreaAutoresize
                          id="exerciseTextToRead"
                          class={errors.answer
                            ? "validation-input-box-error"
                            : ""}
                          style="margin-bottom: 0;"
                          bind:value={$exerciseData.answer}
                          on:ctrlEnter={handleCtrlEnter}
                          placeholder={$_(
                            "EXERCISE_.EXPECTED_ANSWER_PLACEHOLDER",
                          )}
                        />
                        <div
                          class:exercise-char-length-error={exerciseAnswerCharCount >
                            300}
                        >
                          {exerciseAnswerCharCount}
                          {#if exerciseAnswerCharCount > 300}
                            <span>
                              <i class="fas fa-exclamation-circle" />{$_(
                                "EXERCISE_.ANSWER_RECORDING_LIMIT",
                              )}
                            </span>
                          {/if}
                        </div>
                        <div class="validation-text-error">
                          {#if errors.answer}
                            {$_(errors.answer)}
                          {/if}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/if}

              {#if courseConfigData?.organizationCurrentSubscriptionLevel === "TYPE_15"}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <i class="fas fa-info-circle me-1" />{$_(
                      "EXERCISE_XSEC_LIMIT",
                      { values: { seconds: 15 } },
                    )}
                  </div>
                </div>
              {:else}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <i class="fas fa-info-circle me-1" />{$_(
                      "EXERCISE_30SEC_LIMIT",
                    )}
                  </div>
                </div>
              {/if}

              {#if $exerciseData.type === "READ"}
                <input type="checkbox" bind:checked={readabilityDisplayFlag} />
                {#if readabilityDisplayFlag}
                  {#key $exerciseData?.text}
                    <Readability textValue={$exerciseData?.text} />
                  {/key}
                {:else}
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <span
                    on:click={() =>
                      (readabilityDisplayFlag = !readabilityDisplayFlag)}
                    >{$_("READABILITY")}</span
                  >
                {/if}
              {/if}

              {#if exerciseDetailStatus === "loading"}
                <div class="alert alert-success with-top-margin" role="alert">
                  {$_("EXERCISE_SAVED")}
                </div>
              {/if}
            </div>
          </div>

          <div class="card-header py-3">
            <div class="clearfix">
              {#if exerciseDetailStatus != "new" && $exerciseData.id}
                <div class="btn-group" role="group">
                  <button
                    class="btn btn-danger s-button-danger s-mr-10px"
                    on:click={deleteExercise}
                  >
                    <i
                      class="far fa-window-close"
                      style="padding-right: 8px;"
                    />{$_("DELETE")}
                  </button>
                </div>
              {/if}

              <div class="btn-group float-end" role="group">
                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  href="course-table.html"
                  on:click={cancelExerciseEdit}
                >
                  <i
                    class="far fa-window-close"
                    style="padding-right: 8px;"
                  />{$_("CANCEL")}
                </button>

                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  type="button"
                  disabled={exerciseDetailStatus === "saving"}
                  on:click={() => {
                    handleSubmit(false, true);
                  }}
                >
                  <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                    "SAVE_AND_CREATE_NEW",
                  )}
                  <br />
                </button>

                <button
                  class="btn btn-success s-button-success"
                  type="submit"
                  disabled={exerciseDetailStatus === "saving"}
                  on:click|preventDefault={() => {
                    handleSubmit(true);
                  }}
                >
                  <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                    "SAVE",
                  )}
                  <br />
                </button>

                <!-- <button
                class="btn btn-link"
                type="button"
                on:click={() => {
                  handleSubmit(false);
                }}
              >
                <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                  "SAVE_DRAFT"
                )}
                <br />
              </button> -->
              </div>
            </div>
            {#if exerciseDetailStatus === "error"}
              <div class="alert alert-danger" role="alert">
                {$_("EXERCISE_SAVING_ERROR")}
              </div>
            {:else if exerciseDetailStatus === "validationError"}
              <div class="alert alert-danger" role="alert">
                {$_(validationError)}
              </div>
            {:else if exerciseDetailStatus === "saving"}
              <div class="alert alert-info" role="alert">{$_("SAVING")}</div>
            {:else if exerciseDetailStatus === "saved"}
              <div class="alert alert-success" role="alert">
                {$_("EXERCISE_SAVED")}
              </div>
            {/if}
          </div>
        </div>
      </form>
    </div>
  {/if}
</main>

<style global>
  @import "filepond/dist/filepond.css";
  @import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

  .validation-text-error {
    color: red;
    font-size: 0.75em;
    height: 3vh;
  }

  .validation-input-box-error {
    border-color: red;
  }

  .exercise-char-length-error {
    color: red;
  }
</style>
